/* Import Google Font: Garamond */
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600&display=swap');

/* Tailwind CSS utilities */
@tailwind base;      /* Add this line */
@tailwind components; /* Add this line */
@tailwind utilities;


/* Hide scrollbar for Chrome, Safari, and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Autofill background and text color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: #000719 !important; /* Background stays dark */
  color: white !important; /* Force white text color */
  border: 1px solid #ECBC56 !important;
  -webkit-text-fill-color: white !important; /* This is needed for autofill text */
  -webkit-box-shadow: 0 0 0px 1000px #000719 inset !important; /* Force the background color */
  box-shadow: 0 0 0px 1000px #000719 inset !important;
}

input {
 /* Default white text */
  border: 2px solid transparent; /* Transparent border initially */
  outline: none; /* Remove default outline */
  /* Smooth transition for border changes */
}

/* Hide scrollbar for IE, Edge, and Firefox */
html, body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Apply Garamond font globally */
body, input, button, select, textarea {
  font-family: 'Garamond';
  letter-spacing: 0.7px;
}

@media (max-width: 550px) {
    .iphone-flex-col {
      @apply flex-col;
    }
    .widmap{
      @apply w-[200%];
    }
    .padcontact{
      @apply mt-[250px];
      
    }
    .widhero{
      @apply w-auto;
    }
}

/* Base styles for the navigation */
.navigation {
  background-color: #000719;
  color: white;
  padding: 4px 213px;
  position: relative;
  z-index: 20;
  font-family: 'Garamond'; /* Apply Garamond font */
}


.container {
  max-width: 1200px;
  margin: 0 auto;
}

.nav-list {
  display: flex;
  gap: 8px;
  justify-content: center;
}

.nav-item {
  position: relative;
}

.nav-link {
  color: white;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  font-family: 'Garamond'; /* Apply Garamond font */
}

.nav-link:hover {
  color: #ecbc56;
}

/* Dropdown styles */
.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #000719;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: scale(0.95);
  pointer-events: none; /* Prevent clicks when hidden */
  transition: opacity 10s ease-in-out, transform 100s ease-in-out;
}

/* Show the dropdown with a slow 10s transition */
.nav-item:hover .dropdown {
  opacity: 1;
  transform: scale(1);
  pointer-events: auto; /* Enable interaction when visible */
}

.dropdown-container {
  padding: 16px;
}

.dropdown-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-item {
  color: white;
  margin-bottom: 8px;
  cursor: pointer;
  transition: color 10s ease-in-out;
  font-family: 'Garamond'; /* Apply Garamond font */
}

.dropdown-item:hover {
  color: #ecbc56;
}

.no-items {
  color: #808080;
}

/* Increase arrow size */
.slick-prev, .slick-next {
  font-size: 2rem; /* Adjust size as needed */
  width: 40px;     /* Adjust width */
  height: 40px;    /* Adjust height */
}

/* Optional: Customize arrow color */
.slick-prev:before, .slick-next:before {
  color: black; /* Adjust color as needed */
}


@font-face {
  font-family: 'Garamond';
  
  font-style: normal;
  font-weight: normal;
  src: local('Garamond'), url('../public/fonts/AdobeGaramond.otf') format('opentype');
  }


  .arrow-button {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 100%;
  right: 100%;
  transform: translateY(-50%);
  z-index: 10;
  transition: opacity 0.3s ease;
}




@media (max-width: 768px) {
  .arrow-button {
    top: 50%;
    width: 30px;
    height: 30px;
    left: 90%;
    
    

  }


}



:root {
  --primary-gradient: linear-gradient(to right, #B37F2C, #F8DE7D, #FFF2A6, #F8DE7D, #B37F2C);
}

/* ... existing styles ... */
.bg-primarygradient {
  background-image: var(--primary-gradient);
}




.backdrop-blur-desc {
  position: relative;
  color: white;
  text-shadow: 0 0 12px rgba(255, 255, 255, 0.8), /* First, intense glow */
  0 0 12px rgba(255, 255, 255, 0.6), /* Second, softer outer glow */
  0 0 20px rgba(255, 255, 255, 0.4); /* Third, faint outermost glow */
}





